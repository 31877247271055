import React from 'react';
import Navbar from '../components/Navbar';
import Footers from '../components/Footers';
import HeroImg2 from '../components/HeroImg2';
import Work from '../components/Work';

const Project = () => {
  return (
    <div>
      <Navbar />
      <HeroImg2 heading="PROJECTS." text="some of my most recent works" />
      <Work/>
      <Footers />
    </div>
  );
}

export default Project;