import "./FormStyle.css"
import React, { useState } from 'react'

const Form = () => {
 
  const [messageSent, setMessageSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    const form = e.target;
    const requiredInputs = form.querySelectorAll('input[required]');
    const areAllRequiredFieldsFilled = Array.from(requiredInputs).every(input => input.value.trim() !== '');

    if (areAllRequiredFieldsFilled && validateEmail(email)) {
      
      const requestBody = {
        TableName: "ContactTableD",
        Item: {
          Name: { S: name },
          Email: {S:email },
          Subject:  {S: subject},
          Phone: {S: phone},
          Message: {S: message}
        }
      };
      fetch('https://ladga9ma7a.execute-api.us-east-1.amazonaws.com/v1/contact', {
      method: 'POST',
      body: JSON.stringify(requestBody)
    }).then(response => {
      if (response.ok) {
        
        setMessageSent(false);
        setErrorMessage("");
      } else {
        return "Error sending to the Data Base"
      }
    })
    .catch(error => {
      return error;
    });

   
      setTimeout(() => {
        setMessageSent(true);
       setErrorMessage("");
       
      }, 2000);

     
  } else {
    setErrorMessage("Please fill in all required fields correctly.");
  }

  setTimeout(() => {
   setErrorMessage("");
  }, 8000);
     
    }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  }

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  }

  const validateEmail = (email) => {
   
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
    <div className="form">
      <div className="contact-title">
      <h1>Contact</h1>
      </div>
 {errorMessage && <p className="error-message">{errorMessage}</p>}    
      <form onSubmit={handleSubmit}>
        <label>Name</label>
        <input type="text" required value={name} onChange={handleNameChange} />
        <label>Email</label>
        <input type="text" required value={email} onChange={handleEmailChange} />
        <label>Subject</label>
        <input type="text" required value={subject} onChange={handleSubjectChange} />
        <label>Phone</label>
        <input type="text" value={phone} onChange={handlePhoneChange} />
        <label>Message</label>
        <textarea rows="6" placeholder="Type your message" required value={message} onChange={handleMessageChange} />
        {messageSent ? (
          <p className="success-message">Message sent successfully!</p>
        ) : (
          <button className="btn" type="submit">Submit</button>
        )}
      </form>

       
      
      
     
    </div>
  )
}

export default Form