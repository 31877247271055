import React from 'react';
import Navbar from '../components/Navbar';
import Footers from '../components/Footers';
import HeroImg2 from '../components/HeroImg2';
import Form from '../components/Form';


const Contact = () => {
  return (
    <div>
      <Navbar/>
      <HeroImg2 heading="Contact" text="I would greatly appreciate the chance to engage with you and address any inquiries or comments you may have." />
      <Form/>
      <Footers/>

    </div>
  )
}

export default Contact
