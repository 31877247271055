import React from 'react';
import Navbar from '../components/Navbar';
import Footers from '../components/Footers';
import HeroImg2 from '../components/HeroImg2';
import AboutContent from '../components/AboutContent';
import Curriculum from '../components/Curriculum';

const About = () => {
  return (
    <div>
    <Navbar/>
    <HeroImg2 heading="ABOUT ME." text="Let me introduce myself" />
    <AboutContent/>
    <Curriculum/>
    <Footers/>
      
    </div>
  )
}

export default About
