import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './WorkCardStyle.css';
import Modal from 'react-modal';
import Project from './ProjectPop';


const WorkCard = (props) => {
  const words = props.text.split(' ');
  const limitedText = words.length > 40 ? `${words.slice(0, 40).join(' ')}...` : props.text;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleViewClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="project-card">
      <img src={props.imgsrc} alt={props.title} />
      <h4 className="project-title">{props.title}</h4>
      <div className="pro-details">
        <p>{limitedText}</p>
        <div className="pro-btns">
          <button className="btn" onClick={handleViewClick}>
            View
          </button>
          <NavLink to="url.com" className="btn">
            Source
          </NavLink>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>

      


        <Project title ={props.title} text={props.text}  objective ={props.objective} technology={props.technology} step1={props.step1} 
        step1b={props.step1b} step1i={props.step1i} step1a={props.step1a}
        step2={props.step2} step2img={props.step2img} 
        final ={props.final}
        />
        <button  className="close-btn btn " onClick={closeModal}>Close</button>

       
      </Modal>
    </div>
  );
};

export default WorkCard;