import { Link } from "react-router-dom";
import "./AboutContentStyle.css";
import React from 'react';
import AWS from "../assets/aws.jpg";
import react1 from "../assets/react.jpg";
import newImage from "../assets/yo2.jpg";
import pdfFile from  "../assets/camilo.pdf";

const AboutContent = () => {
  return (

    <div className="about_princ">
      <div className="title_about">
      <h1>About Me</h1>
      </div>
      
    <div className="about">
      <div className="left">
        <div className="image-container">
          <img src={newImage} className="new-image" alt="true" />
        </div>
        <h1>Software Engineer</h1>
        <p>Mechatronic engineer from the Universidad Milita Nueva Granada, certified in CSWA Solidworks and high knowledge in different programming languages. 1 year experience in software engineering and testing using Java and the framework ZK. Responsible, dynamic, with desires for improvement and goals based on the achievement of clear objectives; quickly learning and proper compliance with punctuality, honesty, and responsibility.</p>
        <div className="btn-contact">
        <div>
        <Link to="/contact">
          <button className="btn">CONTACT</button>
        </Link>
        </div>
        <div>
        <Link to={pdfFile} target="_blank" download>
        <button className="btn">DOWNLOAD PDF</button>
      </Link>
        </div>
        
        
      </div>
      </div>
      
      <div className="right">
        <div className="img-container">
          <div className="img-stack top">
            <img src={AWS} className="img" alt="true" />
          </div>
          <div className="img-stack bottom">
            <img src={react1} className="img" alt="true" />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AboutContent;