import React from 'react';
import { FaHome, FaPhone, FaMailBulk, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import './FooterStyles.css';

const Footers = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <h4>
              <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
              1000n 4th Street, Fairfield, IA MR422
            </h4>
          </div>
          <div className='phone'>
            <h4>
              <FaPhone size={20} style={{ color: "#fff", marginRight: "2rem" }} />
              641-233-9265
            </h4>
          </div>
          <div className='email'>
            <h4>
              <FaMailBulk size={20} style={{ color: "#fff", marginRight: "2rem" }} />
              camilo.medisarr@gmail.com
            </h4>
          </div>
        </div>
        <div className="right">
          <div className="about">
            <h4>Ivan Camilo Medina Sarmiento</h4>
            
                      </div>
                      <p>Software Engineer</p>
          <div className="social">
          <a href="https://www.facebook.com/camilo.medina.125323/">
            <FaFacebook size={30} style={{ color: "#fff", marginRight: "1rem" }} />
          </a>
          <a href="https://www.linkedin.com/in/ivan-camilo-medina-sarmiento-561b321b6/">
            <FaLinkedin size={30} style={{ color: "#fff", marginRight: "1rem" }} />
          </a>
          <a href="https://www.instagram.com/camilo.medina.30/">
            <FaInstagram size={30} style={{ color: "#fff", marginRight: "1rem" }} />
          </a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Footers;