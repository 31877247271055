import React from 'react';
import pro1 from "../assets/project.jpg";
import { NavLink } from "react-router-dom";
import "./WorkCardStyle.css"
import WorkCard from './workCard';
import WorkCardData from './WorkCardData'


const Work = () => {
  return (
    <div className="work-container">
      <h1 className="project-heading">PROJECTS</h1>
      <div className="project-container">
        {WorkCardData.map((val,ind)=>{
          return(
            <WorkCard
            key={ind}
            imgsrc={val.imgsrc}
            title={val.title}
            text={val.text}
            view={val.view}
            objective ={val.objective}
            technology={val.technology}
            step1 ={val.step1}
            step1i= {val.step1i}
            step1a= {val.step1a}
            step1b= {val.step1b}
            step2 ={val.step2}
            step2img ={val.step2img}
            final ={val.final}

            />
          )
        })}
       
      </div>
    </div>
  );
};

export default Work;