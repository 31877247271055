import React, { useState, useEffect } from 'react';
import IntroImg from "../assets/project.jpg";
import IntroImg1 from "../assets/project2.jpg";
import IntroImg2 from "../assets/project3.jpg";
import arrowImage from "../assets/arrow.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { Link } from "react-router-dom";
import "./HeroImgStyle.css";

const HeroImgs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [IntroImg, IntroImg1, IntroImg2];

  const handleClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handleClickLeft = () => {
    
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex - 1) % images.length;
        return newIndex >= 0 ? newIndex : newIndex + images.length;
      });
   
    };


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => {
      clearInterval(timer); // Clean up the timer on component unmount
    };
  }, []);

  return (
    <div className="slider-container">
      <div className="slider">
        <img className="intro-img" src={images[currentIndex]} alt="img" />
      </div>
      <div className="content">
        <h1>Software Developer</h1>
      </div>
      <div className="btn-content">
        <button className="icon-button" onClick={handleClick}>
          <FontAwesomeIcon className="btn-icon" icon={faArrowRight} />
        </button>
      </div>
      <div className="btn-content-left">
        <button className="icon-button" onClick={handleClickLeft}>
          <FontAwesomeIcon className="btn-icon" icon={faArrowLeft} />
        </button>
      </div>
    </div>
  );
};

export default HeroImgs;