import React from 'react'
import Navbar from '../components/Navbar'
import HeroImgs from '../components/HeroImgs'

import Footers from '../components/Footers'

const Home = () => {
  return (
    <div>
      <Navbar/>
      <HeroImgs/>
      <Footers size={20} style={{color: "#fff", marginRigth:"2rem"}}/>
      
    </div>
  )
}

export default Home
