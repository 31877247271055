import React from 'react';
import './ProjectPopStyle.css';

const ProjectPop = (props) => {
    const technologies= props.technology;
    let  technologyList =[];
    if (technologies!=null){
        technologyList = technologies.map((technology) => <li key={technology}>{"*  "+technology}</li>);
    }
  return (
    <div className="project-pop">
      <h2>{props.title}</h2>
      <h1>Introduction</h1>
      <p>{props.text}</p>
      <h1>Objective</h1>
      <p>{props.objective}</p>
      <h1>Technology Stack</h1>
      <ul>
        {technologyList}
      </ul>
      <h1>Steps</h1>
      <p>{props.step1}</p>
        <div className='img-step-principal'>
      <img  src={props.step1i} alt={props.step1i} />
      </div>
      <div className='img-step'>
   
      <img src={props.step1a} alt={props.step1a} />
      <img src={props.step1b} alt={props.step1b} />
      </div>
      <p>{props.step2}</p>
      <div className='img-step-principal'>
      <img src={props.step2img} alt={props.step2img} />
      </div>
      
      <p>{props.final}</p>
    </div>
  );
};

export default ProjectPop;