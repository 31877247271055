import React from 'react';

const Skill = ({ name, level }) => {
  return (
    <div className="skill">
      <div className="skill-name">{name}</div>
      <div className="skill-bar">
        <div className="skill-level" style={{ width: `${level}%` }}>
          {level}%
        </div>
      </div>
    </div>
  );
};

export default Skill;