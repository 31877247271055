import "./NavBarStyles.css"

import { Link } from "react-router-dom"
import React, { useState } from 'react'
import { FaBars, FaTimes, FaHome, FaProjectDiagram, FaUser, FaEnvelope } from 'react-icons/fa'; 



const Navbar = () => {

    const [click,setClick]= useState(false);
    const handleCLick=() => setClick(!click);

    const [color,setColor] = useState(false);
    const changeColor = () =>{
        if (window.scrollY>=100){
          setColor(true);
        }else{
          setColor(false);
        }
        

    };
    window.addEventListener("scroll",changeColor)
    
  return (
   
    <div className={color ? "header header-bg" : "header"}>
    <Link to="/">
      <h1>Camilo Medina</h1>
    </Link>
    <ul className={click ? "nav-menu active" : "nav-menu"}>
      <li>
        <Link to="/">
          <FaHome />
          <span className="nav-text">Home</span>
        </Link>
      </li>
      <li>
        <Link to="/project">
          <FaProjectDiagram />
          <span className="nav-text">Project</span>
        </Link>
      </li>
      <li>
        <Link to="/about">
          <FaUser />
          <span className="nav-text">About</span>
        </Link>
      </li>
      <li>
        <Link to="/contact">
          <FaEnvelope />
          <span className="nav-text">Contact</span>
        </Link>
      </li>
    </ul>
      <div className="hamburguer" onClick={handleCLick}>
        {click ? (<FaTimes size={20} style={{color:"#fff"}} />):
        <FaBars size={20} style={{color:"#fff"}} />}
            
            
            
        </div>
    </div>
  )
}

export default Navbar